import _axios , {exportExcel} from '@/api/axios'



export default {
// 登录页
    //验证码
    verificationCode(data) {
        return _axios({
            url: '/visitor/capt',
            method: 'post',
            data
        })
    },
    //登录
    login(data) {
        return _axios({
            url: '/visitor/login-asyn',
            method: 'post',
            data
        })
    },
    //退出登录
    visitorLogout(data) {
        return _axios({
            url: '/visitor/logout',
            method: 'post',
            data
        })
    },
    // 代客下单
    visitorDoUndermember(params){
        return _axios({
            url: '/visitor/do-undermember',
            method: 'get',
            params
        })
    },
// 公共接口
    //通用获取用户信息
    buyerBaseInfo(data) {
        return _axios({
            url: '/huarenApi/pc/memberInfo',
            method: 'post',
            data
        })
    },
    // 热搜词
    pcKeywords(){
        return _axios({
            url: '/huarenApi/pc/keywords',
            method: 'get'
        })
    },
    //底部文章
    pcArticle(data) {
        return _axios({
            url: '/huarenApi/pc/article',
            method: 'post',
            data
        })
    },
    //收藏取消收藏
    pcProductDoFav(data) {
        return _axios({
            url: '/huarenApi/pc/product/doFav',
            method: 'post',
            data
        })
    },
    //加入购物车
    pcJoinCar(data) {
        return _axios({
            url: '/huarenApi/pc/joinCar',
            method: 'post',
            data
        })
    },
    //获取购物车数量
    pcCarNum(data) {
        return _axios({
            url: '/huarenApi/pc/carNum',
            method: 'post',
            data
        })
    },
// 首页
    // banner
    pcBanner(){
        return _axios({
            url: '/huarenApi/pc/banner',
            method: 'get'
        })
    },
    // 公告
    pcAlone(){
        return _axios({
            url: '/huarenApi/pc/alone',
            method: 'get'
        })
    },
    // 专题
    pcActivity(params){
        return _axios({
            url: '/huarenApi/pc/activity/show',
            method: 'get',
            params
        })
    },
    // 首页夹层广告
    pcFloorAd(){
        return _axios({
            url: '/huarenApi/pc/floor/ad',
            method: 'get'
        })
    },
    //商品分类
    pcProductCategory(data) {
        return _axios({
            url: '/huarenApi/pc/product/category',
            method: 'post',
            data
        })
    },
    // 专题广告图
    pcIndexTopic(){
        return _axios({
            url: '/huarenApi/pc/index/topic',
            method: 'get'
        })
    },
    //首页楼层
    pcIndexFloor(data) {
        return _axios({
            url: '/huarenApi/pc/index/floor',
            method: 'post',
            data
        })
    },
    // 特价列表
    pcSpecial(data){
        return _axios({
            url: '/huarenApi/pc/special',
            method: 'post',
            data
        })
    },
// 在线下单搜索页
    // 搜索筛选
    pcSearchOption(){
        return _axios({
            url: '/huarenApi/pc/search/option',
            method: 'get'
        })
    },
    //搜索列表页
    pcGoodsSearch(data) {
        return _axios({
            url: '/huarenApi/pc/goodsSearch',
            method: 'post',
            data
        })
    },
    //搜索列表页
    pcGoodsTips(data) {
        return _axios({
            url: '/huarenApi/pc/goods/tips',
            method: 'post',
            data
        })
    },
    //商品预定
    pcGoodsPredetermine(data) {
        return _axios({
            url: '/huarenApi/pc/product/predetermine',
            method: 'post',
            data
        })
    },
// 客户模块
    // 业务员主页
    pcDistributorIndex(data){
        return _axios({
            url: '/huarenApi/pc/distributor/index',
            method: 'post',
            data
        })
    },
    // 我的客户
    pcCustomerList(data){
        return _axios({
            url: '/huarenApi/pc/customer/list',
            method: 'post',
            data
        })
    },
    // 客户订单
    pcOrderList(data){
        return _axios({
            url: '/huarenApi/pc/order/list',
            method: 'post',
            data
        })
    },
    // 客户账龄
    pcBillList(data){
        return _axios({
            url: '/huarenApi/pc/bill/list',
            method: 'post',
            data
        })
    },
// 订单
    // 订单确认收货
    pcConfirmReceiving(data){
        return _axios({
            url: '/huarenApi/pc/confirm/receiving',
            method: 'post',
            data
        })
    },
    // 订单详情
    pcOrderDetail(data){
        return _axios({
            url: '/huarenApi/pc/order/detail',
            method: 'post',
            data
        })
    },
    // 删除意向订单中某条
    pcDeletePrepareOrder(data){
        return _axios({
            url: '/huarenApi/pc/deletePrepareOrder',
            method: 'post',
            data
        })
    },
    // 修改意向订单
    pcEditPrepareOrder(data){
        return _axios({
            url: '/huarenApi/pc/editPrepareOrder',
            method: 'post',
            data
        })
    },
    // 获取意向订单数量
    pcPrepareOrderNum(data){
        return _axios({
            url: '/huarenApi/pc/PrepareOrderNum',
            method: 'post',
            data
        })
    },
    // 下载检验资料
    pcOrderBatchdrug(data){
        return _axios({
            url: '/order/batchdrug ',
            method: 'post',
            data,
            headers:{'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'}
        })
    },
       // 检测地址
       checkAddress(data){
        return _axios({
            url: '/huarenApi/pc/distributor/checkAddress',
            method: 'post',
            data
        })
    },
    //全国地址
    getAllAddress(data){
        return _axios({
            url: '/huarenApi/pc/region',
            method: 'post',
            data
    })
},
//提交地址
subCustomAddress(data){
    return _axios({
        url: '/huarenApi/pc/distributor/setAddress',
        method: 'post',
        data
})
},
//检查应收
checkReceivable(data){
    return _axios({
        url: '/huarenApi/pc/distributor/checkExceed',
        method: 'post',
        data
})
},
//修改密码
changePassword(data){
    return _axios({
        url: '/huarenApi/pc/buyer/editUserPassword',
        method: 'post',
        data
})
},
//我的收藏列表
myCollection(data){
    return _axios({
        url: '/huarenApi/pc/buyer/fav',
        method: 'post',
        data
    })
},
//优惠券列表
getCouponList(data){
    return _axios({
        url: '/huarenApi/pc/buyer/coupon',
        method: 'post',
        data
    })
},
//采购历史
getPurchaseHistory(data){
    return _axios({
        url: '/huarenApi/pc/buyer/buyrecord',
        method: 'post',
        data
    })
  
},
//留言
getLeaveMessage(data){
    return _axios({
        url: '/huarenApi/pc/buyer/message',
        method: 'post',
        data
    })
},
//提交留言
subLeaveMessage(data){
    return _axios({
        url: '/huarenApi/pc/buyer/setMessage',
        method: 'post',
        data
    })
},
//收货地址
getAddress(data){
    return _axios({
        url: '/huarenApi/pc/buyer/address',
        method: 'post',
        data
    })
},
//设置默认地址
setDefaultAddress(data){
    return _axios({
        url: '/huarenApi/pc/buyer/setAddressDefault',
        method: 'post',
        data
    })
},
//  删除地址
deleteAddress(data){
    return _axios({
        url: '/huarenApi/pc/buyer/delAddress',
        method: 'post',
        data
    })
},
// 提交地址
subAddress(data){
    return _axios({
        url: '/huarenApi/pc/buyer/addAddress',
        method: 'post',
        data
    })
},
// 修改地址
editAddress(data){
    return _axios({
        url: '/huarenApi/pc/buyer/editAddress',
        method: 'post',
        data
    })
},
// 获取客户信息
getCustomInfo(data){
    return _axios({
        url: '/huarenApi/pc/buyer/licenseInfo',
        method: 'post',
        data
    })
},
// 待审核列表
waitOrderList(data){
    return _axios({
        url: '/huarenApi/pc/buyer/prepareOrderList',
        method: 'post',
        data
    })
},
// 导出订单
orderExport(data){
    return exportExcel({
        url: '/huarenApi/pc/order/export',
        data,

    })
},
//修改手机号
changePhone(data){
    return _axios({
        url: '/huarenApi/pc/buyer/editUserMobile',
        method: 'post',
        data
    })
},
//获取验证码
getVerificationCode(data){
    return _axios({
        url: '/huarenApi/pc/getCode',
        method: 'post',
        data
    })
},
//再次购买
buyAgain(data){
    return _axios({
        url: '/huarenApi/pc/order/copyOrder',
        method: 'post',
        data
    })
},
// 获取新订单详情
getNewDetail(data){
    return _axios({
        url: '/huarenApi/pc/order/detailV42',
        method: 'post',
        data
    })
},
// 获取退款订单详情
getReturnDetail(data){
    return _axios({
        url: '/huarenApi/pc/buyer/returnDetail',
        method: 'post',
        data
    })
},
// 获取订单状态列表
getMyOrderStatusList(data){
    return _axios({
        url: '/huarenApi/pc/buyer/ordersTab',
        method: 'post',
        data
    })
},
// 获取订单列表
getMyOrderList(data){
    return _axios({
        url: '/huarenApi/pc/buyer/orders',
        method: 'post',
        data
    })
},
// 获取退货列表
getReturnList(data){
    return _axios({
        url: '/huarenApi/pc/buyer/returnList',
        method: 'post',
        data
    })
},
// 获取发票列表
getInvoiceList(data){
    return _axios({
        url: '/huarenApi/pc/order/invoice',
        method: 'post',
        data
    })
},
// 获取物流列表
getDeliveryList(data){
    return _axios({
        url: '/huarenApi/pc/order/wayBill',
        method: 'post',
        data
    })
},
// 获取物流详情
getDeliveryDetailList(data){
    return _axios({
        url: `/api/login/way-bill`,
        method: 'post',
        data
    })
},
//确认收货
confirmReceipt(data){
    return _axios({
        url: '/huarenApi/pc/order/orderReceiving',
        method: 'post',
        data
    })
},
//取消订单
cancelOrder(data){
    return _axios({
        url: '/huarenApi/pc/order/cancleOrder',
        method: 'post',
        data
    })
},
//出库单
outOrder(data){
    return _axios({
        url: '/huarenApi/pc/order/delivery',
        method: 'post',
        data
    })
},
//下载出库单
downloadOutOrder(data){
    return exportExcel({
        url: '/huarenApi/pc/order/deliveryExport',
        method: 'post',
        data
    })
},
//销售数量
saleNumList(data){
    return _axios({
        url: '/huarenApi/pc/distributor/keynoteSchedule',
        method: 'post',
        data
    })
},
//销售数量明细
saleNumDetail(data){
    return _axios({
        url: '/huarenApi/pc/distributor/keynoteScheduleDetail',
        method: 'post',
        data
    })
},
//地址新增图片
subPic(data){
    return _axios({
        url: '/huarenApi/pc/buyer/uploadAddressLicense',
        method: 'post',
        data
    })
},
//重置密码
resetPwd(data){
    return _axios({
        url: '/huarenApi/pc/resetPassword',
        method: 'post',
        data
    })
},
}